interface Specifity {
  id: number;
  name: string;
}

export const specifities: Specifity[] = [
  {
    id: 1,
    name: 'IT Services & Consulting',
  },
  {
    id: 2,
    name: 'Internet',
  },
  {
    id: 3,
    name: 'Cosmetics',
  },
  {
    id: 4,
    name: 'Health Services',
  },
  {
    id: 5,
    name: 'NGO',
  },
  {
    id: 6,
    name: 'Consumer Electronics & Appliances',
  },
  {
    id: 7,
    name: 'FMCG',
  },
  {
    id: 8,
    name: 'Beauty & Fashion',
  },
];
