import React from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Link } from 'react-router-dom';
import logo from 'assets/logo2.png';
import { readUser } from '@app/services/localStorage.service';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  return (
    <BaseRow justify="space-between" align="middle" style={{ maxWidth: '100%' }}>
      {readUser() && (
        <BaseCol>
          <BaseRow align="middle" gutter={[8, 8]}>
            <BaseCol>
              <ProfileDropdown />
            </BaseCol>
            <BaseCol>
              <HeaderSearch />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      )}

      {!readUser() && (
        <>
          <BaseCol>
            <BaseRow align="middle" gutter={[8, 8]}>
              <BaseCol style={{ padding: 0 }}>
                <HeaderSearch />
              </BaseCol>
              <BaseCol>
                <SettingsDropdown style={{ padding: 0 }} />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </>
      )}

      <BaseCol style={{ marginLeft: '-38px' }}>
        <Link to={'/'}>
          <img src={logo} width={120} height={'auto'} />
        </Link>
      </BaseCol>

      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
    </BaseRow>
  );
};
