import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doResetPassword } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { forgotPassword } from '@app/api/auth.api';
import { Alert, Form, message } from 'antd';

interface ForgotPasswordFormData {
  email: string;
}

const initValues = {
  email: '',
};

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const [formSucc, setFormSucc] = useState(null);

  const [form] = Form.useForm();

  const initValues = {
    email: '',
  };

  const handleSubmit = (values) => {
    setLoading(true);

    forgotPassword(values)
      .then(() => {
        setFormErr('');
        setLoading(false);
        message.success('Password reset link sent successfully');
        form.resetFields();
        setFormSucc(
          'A password reset link was sent to your email address. Follow the instructions to reset your password',
        );
      })
      .catch((error) => {
        setFormSucc('');
        setLoading(false);
        message.error(error.message);
        setFormErr(error.message);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initValues}
      >
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.resetPassword')}</Auth.FormTitle>
        <S.Description>{`Enter your email address and we’ll send instructions on how to reset your password`}</S.Description>
        {formErr && <Alert className={'mb-2'} message={formErr} type="error" showIcon />}
        {formSucc && <Alert className={'mb-2'} message={formSucc} type="success" showIcon />}
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[{ required: true, message: t('common.emailError') }]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('forgotPassword.sendInstructions')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
