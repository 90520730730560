import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import LandingContent from '../LandingContent/LandingContent';
import { LandingHeader } from '../LandingHeader/LandingHeader';
import * as S from './LandingLayout.styles';
import { Outlet, useLocation } from 'react-router-dom';
import { MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { References } from '@app/components/common/References/References';
import MainSider from '@app/components/layouts/main/sider/MainSider/MainSider';

const LandingLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(false);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop, isTablet } = useResponsive();
  const location = useLocation();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      {!isDesktop && !isTablet ? <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} /> : <></>}
      <S.LayoutMain>
        <LandingHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </LandingHeader>
        <LandingContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
          {!isTwoColumnsLayout && <References />}
        </LandingContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default LandingLayout;
