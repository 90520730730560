import { Row, Col, Image, Card, Statistic, Steps } from 'antd';
import './landing.css';
import { RecentlyAddedNft } from '@app/components/nft-dashboard/recently-added/RecentlyAddedNft';
import { FavoritesDoctorsCard } from '@app/components/medical-dashboard/favoriteDoctors/FavoriteDoctorsCard/FavoritesDoctorsCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BankTwoTone, BulbTwoTone, CheckCircleOutlined, EditTwoTone, SmileTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import reviews1 from 'assets/images/review-1.jpeg';
import reviews2 from 'assets/images/reviews-2.jpeg';
import reviews3 from 'assets/images/brand-rev2.jpg';
import reviews4 from 'assets/images/brand-rev.png';
import interview from 'assets/images/interview.png';
import verification from 'assets/images/guard.webp';
import comparisonImg from 'assets/images/compare.png';
import { LandingSearch } from '../header/components/HeaderSearch/LandingSearch';
import { formatNumber } from '@app/constants/config/currencies';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { getHomePageStatistics } from '@app/api/company.api';

const { Step } = Steps;

const StyledSteps = styled(Steps)`
  .ant-steps-item {
    .ant-steps-item-icon {
      background: #1890ff;
      border-color: #1890ff;
    }
    .ant-steps-item-title {
      color: #1890ff;
    }
  }
`;

export const Landing = () => {
  const [stats, setStats] = useState({
    monthlyUsers: 105,
    companies: 2300,
    companyReviews: 35000,
    interviews: 15000,
    brands: 1000,
  });

  useEffect(() => {
    getHomePageStatistics().then((res) => {
      setStats(res.data);
    });
  }, []);

  return (
    <div id="homepage" className="homepage-container pd-btm-4 layout-content card-arch">
      <div className="hero-section">
        <div className="container">
          <div className="hero-section__textSection">
            {/*<div className="hero-section__personalizedTitle" style={{ display: 'none' }}>*/}
            {/*  /!* Personalized Title Content *!/*/}
            {/*</div>*/}
            <div className="hero-section__title">
              Nigeria&apos;s No. 1 platform <br />
              for{' '}
              <span className="typewriter-text">
                <span>
                  <span>company reviews</span> <span>salary insights</span> <span>interview advice</span>
                </span>
              </span>
            </div>
            <div className="hero-section__searchbox">
              <LandingSearch />
            </div>
            <div className="hero-section__recently-viewed">
              <div className="hero-section__recent-heading">
                <Image src="https://static.ambitionbox.com/static/homepage/recent-icon.svg" height={24} width={24} />
                <span className="hero-section__recent-title">Recently Viewed</span>
              </div>
              <div className="hero-section__recent-chips">
                <div className="ab_chip hero-section__recent-chip body-medium">
                  <p className="hero-section__chip-text">Amazon</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={reviews4} height="380" width="580" className="hero-section__illustration" />
      </div>
      <div className="container">
        <BaseRow gutter={[30, 30]} style={{ marginTop: '10px' }}>
          <BaseCol span={24}>
            <Row gutter={[30, 30]}>
              {/* <Col xs={12} sm={12} md={8} lg={6}>
                <Card bordered={false} className={'text-center'}>
                  <Statistic
                    title="Monthly Users"
                    value={formatNumber(stats.monthlyUsers)}
                    prefix={<SmileTwoTone style={{ fontSize: '32px' }} />}
                  />
                </Card>
              </Col> */}
              <Col xs={12} sm={12} md={8} lg={6}>
                <Card bordered={false} className={'text-center'}>
                  <Statistic
                    title={<p style={{ fontSize: '18px' }}>Companies</p>}
                    value={formatNumber(stats.companies)}
                    prefix={<BankTwoTone style={{ fontSize: '32px' }} />}
                  />
                </Card>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6}>
                <Card bordered={false} className={'text-center'}>
                  <Statistic
                    title={<p style={{ fontSize: '18px' }}>Brands</p>}
                    value={formatNumber(stats.brands)}
                    prefix={<BankTwoTone style={{ fontSize: '32px' }} />}
                  />
                </Card>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6}>
                <Card bordered={false} className={'text-center'}>
                  <Statistic
                    title={<p style={{ fontSize: '18px' }}>Company Reviews</p>}
                    value={formatNumber(stats.companyReviews)}
                    prefix={<EditTwoTone style={{ fontSize: '32px' }} />}
                  />
                </Card>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6}>
                <Card bordered={false} className={'text-center'}>
                  <Statistic
                    title={<p style={{ fontSize: '18px' }}>Interviews</p>}
                    value={formatNumber(stats.interviews)}
                    prefix={<BulbTwoTone style={{ fontSize: '32px' }} />}
                  />
                </Card>
              </Col>
            </Row>
          </BaseCol>

          <BaseCol span={24}>
            <RecentlyAddedNft />
          </BaseCol>
          <BaseCol id="Companies" xl={24} xxl={12}>
            <FavoritesDoctorsCard />
          </BaseCol>
        </BaseRow>
      </div>
      <div className="container">
        <div className="explore-section">
          <div className="explore-section__header">
            <div className="explore-section__heading">
              <p className="explore-section__title">Explore Limpid</p>
            </div>
            <p className="explore-section__subtitle">
              Nigeria&apos;s largest platform for company reviews, salaries, interview questions and more!
            </p>
          </div>
          <div className="explore-section__explore-cards">
            <div className="explore-card" data-widget="explore_reviews_widget">
              <img
                data-src={reviews1}
                style={{ width: '100%', height: 'auto' }}
                alt="Explore reviews card image"
                className="explore-card__img lazy loaded"
                src={reviews1}
                data-was-processed="true"
              />
              <div className="explore-card__details">
                <p className="explore-card__title" style={{ marginBottom: 0 }}>
                  Explore company reviews to make informed decisions and navigate your career path wisely
                </p>
                <div className="explore-card__links">
                  <a href="/reviews" data-clickable="Explore Company Reviews" className="cta-underline">
                    <div className="cta-underline__container">
                      <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                        Explore Reviews
                      </p>
                      <CheckCircleOutlined />
                    </div>
                  </a>
                  <a href="/compare" data-clickable="Compare Companies" className="cta-underline">
                    <div className="cta-underline__container">
                      <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                        Compare Companies
                      </p>
                      <CheckCircleOutlined />
                    </div>
                  </a>
                </div>
                <Link
                  to={'/reviews/write-review'}
                  target="_blank"
                  data-clickable="Write a review"
                  className="home-hover-button"
                >
                  Write a review
                </Link>
              </div>
            </div>
            <div className="explore-card" data-widget="explore_reviews_widget">
              <img
                data-src={reviews3}
                style={{ width: '100%', height: 'auto' }}
                alt="Explore reviews card image"
                className="explore-card__img lazy loaded"
                src={reviews3}
                data-was-processed="true"
              />
              <div className="explore-card__details">
                <p className="explore-card__title" style={{ marginBottom: 0 }}>
                  Explore Top Brand Reviews, Uncover the best
                </p>
                <div className="explore-card__links">
                  <a href="/reviews?brands=true" data-clickable="Explore Brands Reviews" className="cta-underline">
                    <div className="cta-underline__container">
                      <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                        Explore Reviews
                      </p>
                      <CheckCircleOutlined />
                    </div>
                  </a>
                  <a href="/compare-brands" data-clickable="Compare Companies" className="cta-underline">
                    <div className="cta-underline__container">
                      <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                        Compare Brands
                      </p>
                      <CheckCircleOutlined />
                    </div>
                  </a>
                </div>
                <Link
                  to={'/reviews/brands/write-review'}
                  target="_blank"
                  data-clickable="Write a review"
                  className="home-hover-button"
                >
                  Write a review
                </Link>
              </div>
            </div>
            <div className="explore-card" data-widget="explore_salaries_widget">
              <div className="explore-card__details">
                <p className="explore-card__title" style={{ marginBottom: 0 }}>
                  Explore salaries of more than 500 companies
                </p>
                <div className="explore-card__links">
                  <a href="/salaries" data-clickable="Explore Salaries" className="cta-underline">
                    <div className="cta-underline__container">
                      <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                        Explore Salaries
                      </p>
                      <CheckCircleOutlined />
                    </div>
                  </a>
                  <a href="/salaries/salary-calculator" data-clickable="Salary Calculator" className="cta-underline">
                    <div className="cta-underline__container">
                      <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                        Salary Calculator
                      </p>
                      <CheckCircleOutlined />
                    </div>
                  </a>
                </div>
                <a
                  target="_blank"
                  href="/salaries/add-salary"
                  data-clickable="Share your Salary"
                  className="home-hover-button"
                >
                  Share your Salary
                </a>
              </div>
              <img
                data-src={reviews2}
                style={{ width: '100%', height: 'auto' }}
                alt="Explore salaries card image"
                className="explore-card__img lazy loaded"
                src={reviews2}
                data-was-processed="true"
              />
            </div>
            <div className="explore-card" data-widget="explore_interviews_widget">
              <img
                data-src={interview}
                style={{ width: '100%', height: 'auto' }}
                alt="Explore interviews card image"
                className="explore-card__img lazy loaded"
                src={interview}
                data-was-processed="true"
              />
              <div className="explore-card__details">
                <p className="explore-card__title" style={{ marginBottom: 0 }}>
                  Company-wise Interview Questions
                </p>
                <div className="explore-card__links">
                  <a href="/interviews" data-clickable="Company Interviews" className="cta-underline">
                    <div className="cta-underline__container">
                      <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                        Company Interviews
                      </p>
                      <CheckCircleOutlined />
                    </div>
                  </a>
                  <a href="/colleges" data-clickable="Campus Placements" className="cta-underline">
                    <div className="cta-underline__container">
                      <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                        Internship Placements
                      </p>
                      <CheckCircleOutlined />
                    </div>
                  </a>
                </div>
                <a
                  target="_blank"
                  href="/interviews/share-interview-tips"
                  data-clickable="Share Interview Questions"
                  className="home-hover-button"
                >
                  Share Interview Questions
                </a>
              </div>
            </div>
            <div className="explore-card" data-widget="explore_companies_widget">
              <img
                data-src={comparisonImg}
                style={{ width: '100%', height: 'auto' }}
                alt="Explore companies card image"
                className="explore-card__img lazy loaded"
                src={comparisonImg}
                data-was-processed="true"
              />
              <div className="explore-card__details">
                <p className="explore-card__title" style={{ marginBottom: 0 }}>
                  Find out all there is to know about the company you like!
                </p>
                <div className="explore-card__links">
                  <a data-clickable="Discover Companies" href="/companies" className="cta-underline">
                    <div className="cta-underline__container">
                      <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                        Discover Companies
                      </p>
                      <CheckCircleOutlined />
                    </div>
                  </a>
                  <a href="/compare" data-clickable="Compare Companies" className="cta-underline">
                    <div className="cta-underline__container">
                      <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                        Compare Companies
                      </p>
                      <CheckCircleOutlined />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="explore-card" data-widget="explore_companies_widget">
          <div className="explore-card__details">
            <p className="explore-card__title" style={{ marginBottom: 0 }}>
              Claim Your Company for Free
            </p>
            <div className="explore-card__links">
              <a data-clickable="Discover Companies" href="/companies" className="cta-underline">
                <div className="cta-underline__container">
                  <p className="cta-underline__text" style={{ marginBottom: 0 }}>
                    Claim Company
                  </p>
                  <CheckCircleOutlined />
                </div>
              </a>
            </div>
            <StyledSteps direction="vertical" current={0}>
              <Step title="Register" description="Create an account to get started." />
              <Step title="Company Verification" description="Verify your company's details." />
              <Step title="Update Company Profile" description="Fill in your company's profile information." />
            </StyledSteps>
          </div>

          <img
            data-src={verification}
            style={{ width: '50%', height: 'auto' }}
            alt="Explore companies card image"
            className="explore-card__img lazy loaded"
            src={verification}
            data-was-processed="true"
          />
        </div>
      </div>
    </div>
  );
};
