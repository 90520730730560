export interface NftItem {
  image: string;
  title: string;
  author: string;
  currentBid: number;
  currentBidCrypto: number;
}

export const getRecentlyAddedNfts = (): Promise<NftItem[]> => {
  return new Promise((res) => {
    res([
      {
        image: 'https://i.pinimg.com/736x/da/20/75/da2075601a03cf082b1e5025d41c23dc.jpg',
        title: 'Nestle',
        author: 'nestle',
        currentBid: 3521,
        currentBidCrypto: 1.63,
      },
      {
        image: 'https://dailytrust.com/wp-content/uploads/2021/07/Chi-Limited-Logo.jpg',
        title: 'Chi',
        author: 'chi',
        currentBid: 3150,
        currentBidCrypto: 1.02,
      },
      {
        image: 'https://images.seeklogo.com/logo-png/52/1/oraimo-logo-png_seeklogo-527005.png',
        title: 'Oraimo',
        author: 'oraimo',
        currentBid: 7210,
        currentBidCrypto: 2.08,
      },
      {
        image: 'https://www.newagechargers.com/wp-content/uploads/2021/10/website-Logo-For-Footer.png',
        title: 'New Age',
        author: 'new_age',
        currentBid: 740,
        currentBidCrypto: 1.56,
      },
      {
        image:
          'https://upload.wikimedia.org/wikipedia/en/thumb/e/e9/Chicken_Republic_Logo.svg/1200px-Chicken_Republic_Logo.svg.png',
        title: 'Chicken Republic',
        author: 'chicken_republic',
        currentBid: 311,
        currentBidCrypto: 1.04,
      },
    ]);
  });
};
