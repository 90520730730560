import { ApiError } from './ApiError';
import { httpApi } from './http.api';
import axios, { AxiosError } from 'axios';

export interface CompanyData {
  data: any;
}

export interface CompanyRequest {
  name: string;
}

const baseURL = 'https://codeminds-api.azurewebsites.net';

export const getStates = (): Promise<any> => axios.get(`${baseURL}/v1/states`).then(({ data }) => data);

export const getCompanies = (page = 1, size = 10): Promise<any> =>
  axios.get(`${baseURL}/v1/companies?page=${page}&limit=${size}`).then(({ data }) => data);

export const getBrands = (page = 1, size = 10): Promise<any> =>
  axios.get(`${baseURL}/v1/brands?page=${page}&limit=${size}`).then(({ data }) => data);

export const searchCompanies = (keyword: string): Promise<any> =>
  axios.get(`${baseURL}/v1/companies/search?keyword=${keyword}`).then(({ data }) => data);

export const compareCompanies = (company1: string, company2: string): Promise<any> =>
  axios
    .get(`${baseURL}/v1/companies/compare/companies?company1=${company1}&company2=${company2}`)
    .then(({ data }) => data);

export const compareBrands = (brand1: string, brand2: string): Promise<any> =>
  axios.get(`${baseURL}/v1/brands/compare/brands?brand1=${brand1}&brand2=${brand2}`).then(({ data }) => data);

export const getCompany = (slug: string): Promise<any> =>
  axios.get(`${baseURL}/v1/companies/${slug}`).then(({ data }) => data);

export const getBrand = (slug: string): Promise<any> =>
  axios.get(`${baseURL}/v1/brands/${slug}`).then(({ data }) => data);

export const addCompany = (payload: CompanyRequest): Promise<any> =>
  axios.post(`${baseURL}/v1/companies`, { ...payload }).then(({ data }) => data);

export const addBenefits = (payload: any): Promise<any> =>
  axios.post(`${baseURL}/v1/company-benefits`, { ...payload }).then(({ data }) => data);

export const getDesignations = (): Promise<any> => axios.get(`${baseURL}/v1/designations`).then(({ data }) => data);

export const addDesignation = (payload: CompanyRequest): Promise<any> =>
  axios.post(`${baseURL}/v1/designations`, { ...payload }).then(({ data }) => data);

export const searchRoles = (keyword: string): Promise<any> =>
  axios.get(`${baseURL}/v1/designations/search?keyword=${keyword}`).then(({ data }) => data);

export const getDepartments = (): Promise<any> => axios.get(`${baseURL}/v1/departments`).then(({ data }) => data);

export const getCompanyStatistics = (slug: string): Promise<any> =>
  axios.get(`${baseURL}/v1/companies/statistics/${slug}/general`).then(({ data }) => data);

export const getBrandStatistics = (slug: string): Promise<any> =>
  axios.get(`${baseURL}/v1/brands/statistics/${slug}/general`).then(({ data }) => data);

export const getHomePageStatistics = (): Promise<any> => axios.get(`${baseURL}/v1/home/stats`).then(({ data }) => data);

export const addReview = (payload: any): Promise<any> =>
  axios.post(`${baseURL}/v1/company-reviews`, { ...payload }).then(({ data }) => data);

export const addInterview = (payload: any): Promise<any> =>
  axios.post(`${baseURL}/v1/company-interviews`, { ...payload }).then(({ data }) => data);

export const addBrandReview = (payload: any): Promise<any> =>
  axios.post(`${baseURL}/v1/brand-reviews`, { ...payload }).then(({ data }) => data);

export const addBrand = (payload: CompanyRequest): Promise<any> =>
  axios.post(`${baseURL}/v1/brands`, { ...payload }).then(({ data }) => data);

export const addSalary = (payload: any): Promise<any> =>
  axios.post(`${baseURL}/v1/company-salaries`, { ...payload }).then(({ data }) => data);

export const searchBrands = (keyword: string): Promise<any> =>
  axios.get(`${baseURL}/v1/brands/search?keyword=${keyword}`).then(({ data }) => data);

export const getCompanyReviews = (companyId: string, filters = {}, page = 1, size = 10) => {
  // Remove empty filters
  const validFilters = Object.fromEntries(Object.entries(filters).filter(([key, value]) => value && value.length > 0));

  const queryParams = new URLSearchParams({ page, size, ...validFilters }).toString();
  return axios.get(`${baseURL}/v1/companies/${companyId}/reviews?${queryParams}`).then(({ data }) => data);
};

export const getBrandReviews = (brandId: string, filters = {}, page = 1, size = 10) => {
  // Remove empty filters
  const validFilters = Object.fromEntries(Object.entries(filters).filter(([key, value]) => value && value.length > 0));

  const queryParams = new URLSearchParams({ page, size, ...validFilters }).toString();
  return axios.get(`${baseURL}/v1/brands/${brandId}/reviews?${queryParams}`).then(({ data }) => data);
};

export const getCompanyInterviews = (companyId: string, filters = {}, page = 1, size = 10) => {
  // Remove empty filters
  const validFilters = Object.fromEntries(Object.entries(filters).filter(([key, value]) => value && value.length > 0));

  const queryParams = new URLSearchParams({ page, size, ...validFilters }).toString();
  return axios.get(`${baseURL}/v1/companies/${companyId}/interviews?${queryParams}`).then(({ data }) => data);
};

export const getCategoryRatings = (company: string): Promise<any> =>
  axios.get(`${baseURL}/v1/company-reviews/category-ratings/${company}`).then(({ data }) => data);

export const getBrandCategoryRatings = (brandId: string): Promise<any> =>
  axios.get(`${baseURL}/v1/brand-reviews/category-ratings/${brandId}`).then(({ data }) => data);

export const getCompanyRatingCount = (companyId: string): Promise<any> =>
  axios.get(`${baseURL}/v1/company-reviews/ratings-count/${companyId}`).then(({ data }) => data);

export const getBrandRatingCount = (brandId: string): Promise<any> =>
  axios.get(`${baseURL}/v1/brand-reviews/ratings-count/${brandId}`).then(({ data }) => data);

export const getCompanyInterviewRatingCount = (company: string): Promise<any> =>
  axios.get(`${baseURL}/v1/company-interviews/ratings-count/${company}`).then(({ data }) => data);

export const getCompanyInterviewPercentages = (company: string): Promise<any> =>
  axios.get(`${baseURL}/v1/company-interviews/percentages/${company}`).then(({ data }) => data);

export const getCompanySalaries = (company: string, filters = {}, page = 1, size = 10) => {
  // Remove empty filters
  const validFilters = Object.fromEntries(Object.entries(filters).filter(([key, value]) => value && value.length > 0));

  const queryParams = new URLSearchParams({ page, size, ...validFilters }).toString();
  return axios.get(`${baseURL}/v1/companies/${company}/salaries?${queryParams}`).then(({ data }) => data);
};

export const getCompanyBenefits = (company: string, filters = {}) => {
  // Remove empty filters
  const validFilters = Object.fromEntries(Object.entries(filters).filter(([key, value]) => value && value.length > 0));

  const queryParams = new URLSearchParams(validFilters as Record<string, string>).toString();
  return axios.get(`${baseURL}/v1/companies/${company}/benefits?${queryParams}`).then(({ data }) => data);
};

export const likeEntity = (category: string, entity: string): Promise<any> =>
  axios.post(`${baseURL}/v1/feedback/${category}/${entity}/like`).then(({ data }) => data);

export const dislikeEntity = (category: string, entity: string): Promise<any> =>
  axios.post(`${baseURL}/v1/feedback/${category}/${entity}/dislike`).then(({ data }) => data);
