import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useAppSelector } from '@app/hooks/reduxHooks';
import logo from 'assets/logo2.png';

interface LoadingProps {
  size?: string;
}

export const Loading: React.FC<LoadingProps> = ({ size }) => {
  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <SpinnerContainer>
      <LogoAnimation src={logo} alt="Loading..." $size={size} $theme={'light'} />
    </SpinnerContainer>
  );
};

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoAnimation = styled.img<{ $size: string; $theme: string }>`
  width: ${({ $size }) => $size || '3rem'};
  height: ${({ $size }) => $size || '3rem'};
  animation: ${blinkAnimation} 2s infinite;
  filter: ${({ $theme }) => ($theme === 'dark' ? 'invert(1)' : 'none')};
`;
