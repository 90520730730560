import React, { useEffect, useMemo, useState } from 'react';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { BaseCarousel } from '@app/components/common/BaseCarousel/Carousel';
import { DoctorCard } from '../DoctorCard/DoctorCard';
import { Doctor, getDoctorsData } from '@app/api/doctors.api';
import * as S from './FavoritesDoctorsCard.styles';
import { BREAKPOINTS } from '@app/styles/themes/constants';
import { Link } from 'react-router-dom';

/* eslint-disable @typescript-eslint/no-explicit-any */
const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return <S.SliderArrow className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return <S.SliderArrow className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
};

export const FavoritesDoctorsCard: React.FC = () => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);

  useEffect(() => {
    getDoctorsData().then((res) => setDoctors(res));
  }, []);

  return (
    <DashboardCard title="Explore Companies" padding="0 20px">
      {doctors.length > 0 && (
        <S.CarouselWrapper>
          <BaseCarousel
            arrows={true}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
            slidesToShow={4}
            responsive={[
              {
                breakpoint: 1931,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 1530,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: BREAKPOINTS.xl - 1,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 1140,
                settings: {
                  slidesToShow: 5,
                },
              },
              {
                breakpoint: 920,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: BREAKPOINTS.md - 1,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 720,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 520,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {doctors.map((doc) => {
              return (
                <div key={doc?.id}>
                  <Link to={''}>
                    <DoctorCard
                      imgUrl={doc?.imgUrl}
                      name={doc?.name}
                      speciality={doc?.specifity}
                      rating={doc?.rating}
                    />
                  </Link>
                </div>
              );
            })}
          </BaseCarousel>
        </S.CarouselWrapper>
      )}
    </DashboardCard>
  );
};
