import { httpApi } from '@app/api/http.api';
import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';
import axios, { AxiosError } from 'axios';

export const http = axios.create({
  baseURL: 'https://codeminds-api.azurewebsites.net/',
});

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  fname: string;
  lname: string;
  email_address: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  http.post<LoginResponse>('v1/auth/login', { ...loginPayload }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> => {
  http.post<undefined>('v1/auth/register', { ...signUpData }).then(({ data }) => data);
};

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const forgotPassword = (forgotPasswordData: ResetPasswordRequest) =>
  http.post('v1/auth/reset-password-request', { ...forgotPasswordData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload, token, userId) =>
  http.post(`v1/auth/reset-password?token=${token}&id=${userId}`, { ...resetPasswordPayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  http.post('v1/auth/change-password', { ...newPasswordData }).then(({ data }) => data);
