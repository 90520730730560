import React, { useState } from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import {
  AppstoreOutlined,
  FireOutlined,
  InteractionOutlined,
  LoginOutlined,
  MoneyCollectFilled,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { readUser } from '@app/services/localStorage.service';
import { Link } from 'react-router-dom';
import logo from 'assets/logo2.png';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow>
        <BaseCol xl={6} xxl={4} style={{ alignItems: 'center', textAlign: 'center' }}>
          <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]} justify={'center'}>
            <BaseCol>
              <Link to={'/'}>
                <img src={logo} width={90} height={'auto'} />
              </Link>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol>
          <BaseButton type="text" icon={<AppstoreOutlined />} href="/companies">
            Companies
          </BaseButton>
        </BaseCol>
        <BaseCol>
          <BaseButton type="text" icon={<FireOutlined />} href="/brands">
            Brands
          </BaseButton>
        </BaseCol>
        <BaseCol>
          <BaseButton type="text" icon={<UserSwitchOutlined />} href="/">
            Community
          </BaseButton>
        </BaseCol>
        <BaseCol>
          <BaseButton type="text" icon={<MoneyCollectFilled />} href="/">
            Salaries
          </BaseButton>
        </BaseCol>
        <BaseCol>
          <BaseButton type="text" icon={<InteractionOutlined />} href="/">
            Interviews
          </BaseButton>
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={6} xxl={4} style={{ marginTop: '-20px', alignItems: 'center' }}>
        <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]} justify={'center'}>
          <BaseCol>
            <Link to={'/'}>
              <img src={logo} width={170} height={'auto'} />
            </Link>
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseCol>
        <BaseButton type="text" icon={<AppstoreOutlined />} href="/companies">
          Companies
        </BaseButton>
      </BaseCol>
      <BaseCol>
        <BaseButton type="text" icon={<FireOutlined />} href="/brands">
          Brands
        </BaseButton>
      </BaseCol>
      <BaseCol>
        <BaseButton type="text" icon={<UserSwitchOutlined />} href="/">
          Community
        </BaseButton>
      </BaseCol>
      <BaseCol>
        <BaseButton type="text" icon={<MoneyCollectFilled />} href="/">
          Salaries
        </BaseButton>
      </BaseCol>
      <BaseCol>
        <BaseButton type="text" icon={<InteractionOutlined />} href="/">
          Interviews
        </BaseButton>
      </BaseCol>
    </>
  );

  return (
    <BaseRow align="middle">
      {leftSide}

      <S.ProfileColumn xl={6} xxl={5} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[5, 5]}>
          <BaseCol>
            <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>
              {readUser() ? (
                <BaseCol>
                  <NotificationsDropdown />
                </BaseCol>
              ) : (
                <></>
              )}

              <BaseCol>
                <SettingsDropdown />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            {readUser() ? (
              <ProfileDropdown />
            ) : (
              <BaseButton type={'default'} icon={<LoginOutlined />} shape={'round'} size={'small'} href="/auth/login">
                Login
              </BaseButton>
            )}
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};
