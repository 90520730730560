export interface Doctor {
  id: number;
  name: string;
  specifity: number;
  rating: number;
  gps?: {
    latitude: number;
    longitude: number;
  };
  imgUrl: string;
  phone: string;
  address: string;
}

export const getDoctorsData = (): Promise<Doctor[]> => {
  return new Promise((res) => {
    setTimeout(() => {
      res([
        {
          id: 1,
          name: 'Google',
          specifity: 1,
          rating: 5,
          gps: {
            latitude: 51.505,
            longitude: -0.09,
          },
          imgUrl: 'https://kmc.solutions/media/2bbjc3n5/customize-offices.jpg?width=804&height=402&mode=max',
          phone: '+X-XXX-XXX-XXXX',
          address: '98 Santa Clara Court Cherry Hill, NJ 08003',
        },
        {
          id: 2,
          name: 'Microsoft',
          specifity: 2,
          rating: 5,
          gps: {
            latitude: 41.732438,
            longitude: 44.7688134,
          },
          imgUrl:
            'https://www.wework.com/ideas/wp-content/uploads/sites/4/2021/02/Web_150DPI-20191213_WeWork_One-Seaport-Square-Boston_011_v1.jpg',
          phone: '+X-XXX-XXX-XXXX',
          address: '850 South Tunnel St. Newburgh, NY 12550',
        },
        {
          id: 3,
          name: 'Paystack',
          specifity: 3,
          rating: 5,
          gps: {
            latitude: 40.73061,
            longitude: -73.935242,
          },
          imgUrl: 'https://kmc.solutions/media/2bbjc3n5/customize-offices.jpg?width=804&height=402&mode=max',
          phone: '+X-XXX-XXX-XXXX',
          address: '111 Foxrun Street Conyers, GA 30012',
        },
        {
          id: 4,
          name: 'Fair Money',
          specifity: 4,
          rating: 5,
          imgUrl:
            'https://www.wework.com/ideas/wp-content/uploads/sites/4/2021/02/Web_150DPI-20191213_WeWork_One-Seaport-Square-Boston_011_v1.jpg',
          phone: '+X-XXX-XXX-XXXX',
          address: '9 Wagon Street Ravenna, OH 44266',
        },
        {
          id: 5,
          name: 'Hustle & Bustle',
          specifity: 5,
          rating: 4,
          gps: {
            latitude: 59.334122,
            longitude: 18.071997,
          },
          imgUrl: 'https://kmc.solutions/media/2bbjc3n5/customize-offices.jpg?width=804&height=402&mode=max',
          phone: '+X-XXX-XXX-XXXX',
          address: '9568 Tower St. Somerset, NJ 08873',
        },
        {
          id: 6,
          name: 'Kings care Hospital',
          specifity: 6,
          rating: 5,
          gps: {
            latitude: -26.195246,
            longitude: 28.034088,
          },
          imgUrl:
            'https://www.wework.com/ideas/wp-content/uploads/sites/4/2021/02/Web_150DPI-20191213_WeWork_One-Seaport-Square-Boston_011_v1.jpg',
          phone: '+X-XXX-XXX-XXXX',
          address: '850 South Tunnel St. Newburgh, NY 12550',
        },
        {
          id: 7,
          name: 'Dangote',
          specifity: 7,
          rating: 5,
          gps: {
            latitude: 55.17111,
            longitude: -118.796928,
          },
          imgUrl: 'https://kmc.solutions/media/2bbjc3n5/customize-offices.jpg?width=804&height=402&mode=max',
          phone: '+X-XXX-XXX-XXXX',
          address: '322 South Del Monte Rd. West Hempstead, NY 11552',
        },
        {
          id: 8,
          name: 'Premium Beauty',
          specifity: 8,
          rating: 4,
          gps: {
            latitude: -22.908333,
            longitude: -43.196388,
          },
          imgUrl:
            'https://www.wework.com/ideas/wp-content/uploads/sites/4/2021/02/Web_150DPI-20191213_WeWork_One-Seaport-Square-Boston_011_v1.jpg',
          phone: '+X-XXX-XXX-XXXX',
          address: '7634 Taylor St. Boston, MA 02127',
        },
      ]);
    }, 0);
  });
};
