import axios, { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';

export const httpApi = axios.create({
  baseURL: 'https://codeminds-api.azurewebsites.net/',
});

httpApi.interceptors.request.use((config) => {
  const token = readToken();
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  } else if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  console.log('Request URL:', config.baseURL + config.url); // Log the full request URL
  return config;
});

httpApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError<ApiErrorData>) => {
    console.error('Request Error:', error, error.response);
    const message = error.response?.data?.message || error.message;
    throw new ApiError<ApiErrorData>(message, error.response?.data);
  },
);

export interface ApiErrorData {
  message: string;
}

// Function to test the API call
// async function testApi() {
//   try {
//     const response = await httpApi.get('v1/companies');
//     console.log('API Response:', response.data);
//   } catch (error) {
//     if (error instanceof ApiError) {
//       console.error('API Error:', error.message);
//     } else {
//       console.error('Unexpected Error:', error);
//     }
//   }
// }

// // Call the test function
// testApi();
