export type CategoryType = 'companies' | 'brands' | 'designations' | 'departments';

interface Category {
  name: CategoryType;
  title: string;
}

export const categoriesList: Category[] = [
  {
    name: 'companies',
    title: 'companies',
  },
  {
    name: 'brands',
    title: 'brands',
  },
  {
    name: 'designations',
    title: 'designations',
  },
  {
    name: 'departments',
    title: 'departments',
  },
];
