import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { SignUpForm } from '@app/components/auth/SignUpForm/SignUpForm';
import { Landing } from '@app/components/apps/Landing';

const LandingPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{'Welcome'}</PageTitle>
      <Landing />
    </>
  );
};

export default LandingPage;
